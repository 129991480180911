import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <path
      d="M40.98 50.3C48.55 53.91 49.34 54.59 49.68 57.86C50.24 63.51 46.29 65.43 36.35 64.64C29.24 64.07 28.44 64.19 28.44 66C28.44 68.49 31.38 69.5 38.95 69.5C49 69.5 55.55 64.86 55.55 57.64C55.55 52.55 52.61 49.27 45.5 46.12C37.71 42.61 35.22 40.58 35.22 37.19C35.22 32.9 39.85 30.64 47.08 31.43C52.61 31.99 53.29 31.76 53.29 29.96C53.29 26.35 41.77 25.21 35.79 28.26C31.15 30.75 29.57 33.12 29.57 37.87C29.57 43.63 31.49 45.66 40.98 50.3Z"
      fill="currentColor"
    />
    <path
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M42 6.28L83.72 48L42 89.72L0.28 48L42 6.28Z"
    />
  </svg>
);

export default IconLogo;
