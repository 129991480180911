import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="S">
        <path
          d="M48.7 52.94C58.38 57.56 59.39 58.43 59.83 62.62C60.54 69.84 55.48 72.3 42.77 71.28C33.67 70.56 32.66 70.71 32.66 73.02C32.66 76.2 36.41 77.5 46.1 77.5C58.96 77.5 67.34 71.57 67.34 62.33C67.34 55.82 63.58 51.63 54.48 47.59C44.51 43.1 41.33 40.51 41.33 36.17C41.33 30.68 47.26 27.79 56.49 28.8C63.58 29.52 64.45 29.23 64.45 26.92C64.45 22.3 49.71 20.85 42.05 24.75C36.12 27.93 34.1 30.97 34.1 37.04C34.1 44.41 36.55 47.01 48.7 52.94Z"
          fill="currentColor"
        />
      </g>
      <g id="rect">
        <path
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M50 -0.2L100.2 50L50 100.2L-0.2 50L50 -0.2Z"
        />
      </g>
    </g>
  </svg>
);

export default IconLoader;
